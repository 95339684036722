<template>
  <div>
    <feather-icon icon="LayersIcon" size="16" />
    <span
      @click="
        getRoles();
        getMemberRoles(member.id);
        $bvModal.show(member.id.toString());
        
      "
      class="align-middle ml-50"
    >
      Edit Role
    </span>
    <b-modal
      :id="member.id.toString()"
      :title="title"
      cancel-variant="outline-secondary"
      size="lg"
      no-close-on-backdrop
    >
      <b>List Role {{ member.email }}</b>
      <b-table responsive="sm" :items="member_roles" :fields="fields"> 
        <template #cell(delete)="data">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon" @click="deleteRole(data.item.id)">
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
    </b-table>
    <b>Tambah Role</b>
      <b-form>
        <div class="row"></div>
        <b-form-group  label-for="vue-select">
          <v-select
            id="vue-select"
            label="name"
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="roles"
          />
        </b-form-group>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" @click="addRole()">
          
          <feather-icon icon="SendIcon" />
          Tambah
        </b-button>
        <div style="padding-top:20px">
          <b>Set Is Priority</b> <br><br>
            <b-form-checkbox
              :checked="member.isPriority"
              name="check-button"
              switch
              v-model="isPriority"
              >
              {{member.isPriority ? 'Priority' : 'Not Priority'}}
            </b-form-checkbox>
        </div>
        
      </b-form>


      
      
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BTable, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import API from '@/api'
import member from '@/api/modules/member'
import { is } from 'vee-validate/dist/rules'
export default {
  props: ['member'],
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTable,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: { 'id': 1, 'name': 'alumni' },
      option: ['USA', 'Canada', 'Maxico'],
      title: "Config Role " + this.member.email,
      roles: [],
      member_roles: [],
      fields:['name', 'delete'],
      isPriority:true,
      currentIsPriority:true
    }
  },
  methods: {
    async getRoles() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        const { data } = await API.master.role()
        this.roles = data
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },

    async getMemberRoles() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        const { data } = await API.member.member_role({ 'member_id': this.member.id })
        this.member_roles = data
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },

    async addRole() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        var input = { 'member_id': this.member.id, 'role_id':this.selected.id }
        const { } = await API.member.add_role(input)
        this.handleMsgSuccess()
        this.getMemberRoles()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },

    async deleteRole(role_id) {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        var input = { 'member_id': this.member.id, 'role_id': role_id }
        const { } = await API.member.delete_role(input)
        this.handleMsgSuccess()
        this.getMemberRoles()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
    async changeIsPriority() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        var input = { 'member_id': this.member.id }
        if (this.isPriority) {
          const { } = await API.member.isPriority(input)
        }else{
           const { } = await API.member.isNotPriority(input)
        }
        
        this.handleMsgSuccess()
        this.currentIsPriority = this.isPriority
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
    setIsPriority(isPriority){
      this.currentIsPriority=isPriority
      this.isPriority=isPriority
    }
  },
   watch: {
    isPriority() {
      console.log(this.isPriority)
      if (this.isPriority != this.currentIsPriority) {
          this.changeIsPriority()
      }
    },
   }
}
</script>