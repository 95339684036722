<template>
  <b-card-code title="" no-body>
    <b-card-body
      ><h3>Total Member : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Cari" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Search"
              v-model="search"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Status" label-for="status">
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :options="statusList"
            />
          </b-form-group>
        </b-col>

        <Can i="read" a="MemberFakultas">
          <b-col md="2" xl="2" class="mb-1">
            <!-- basic -->
            <b-form-group label="Fakultas" label-for="tahunMasuk">
              <v-select
                v-model="selectedFakultas"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="nama_fakultas"
                :options="fakultass"
              />
            </b-form-group>
          </b-col>
        </Can>

        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Jurusans" label-for="tahunMasuk">
            <v-select
              v-model="selectedJurusans"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="nama_jurusan"
              :options="jurusans"
            />
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="isAdvanceFilter = !isAdvanceFilter"
          >
            <feather-icon icon="ChevronsDownIcon" class="mr-25" />
            <span>Advance Filter</span>
          </b-button>
        </b-col>
        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="isAdvanceFilter">
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Tahun Masuk" label-for="tahunMasuk">
            <b-form-input
              id="tahunMasuk"
              placeholder="1950"
              type="number"
              v-model="angkatan"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Join Date" label-for="joinDate">
            <b-form-input id="joinDate" type="date" v-model="created_at" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <Can i="write" a="Member">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="selectAllRows"
            >
              Select All
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="clearSelected"
            >
              Clear Selected
            </b-button>
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              text="Action"
              variant="outline-primary"
            >
              <b-dropdown-item @click="validateVerifyMember()">
                Verify All Selected</b-dropdown-item
              >
              <b-dropdown-item @click="validateUnverifyMember()"
                >Unverify All Selected</b-dropdown-item
              >
              <b-dropdown-item @click="validateDeleteMember()"
                >Delete All Selected</b-dropdown-item
              >
            </b-dropdown>
          </b-button-group>
        </Can>

        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <Can i="write" a="Member">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="validateExport"
            >
              <feather-icon icon="DownloadCloudIcon" />
            </b-button>
          </Can>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="getFields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a v-if="data.item.document" :href="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(is_email_verified)="data">
        <b-badge :variant="statusEmail[1][data.value]">
          {{ statusEmail[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailMember(data.item)">
            <feather-icon icon="AwardIcon" size="16" />
            <span class="align-middle ml-50">Riwayat Pendidikan</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <role-modal :member="data.item" />
          </b-dropdown-item>
          <b-dropdown-item
            @click="showDetailMemberUnverify(data.item)"
            v-if="data.item.status == 4"
          >
            <feather-icon icon="ZoomInIcon" size="16" />
            <span class="align-middle ml-50">Detail Unverify</span>
          </b-dropdown-item>

          <Can i="write" a="MemberVerify">
            <b-dropdown-item @click="verifySingle(data.item)">
              <feather-icon icon="CheckSquareIcon" size="16" />
              <span class="align-middle ml-50">Verify</span>
            </b-dropdown-item>
            <b-dropdown-item @click="unverifySingle(data.item)">
              <feather-icon icon="XSquareIcon" size="16" />
              <span class="align-middle ml-50">Unverify</span>
            </b-dropdown-item>
          </Can>
          <b-dropdown-item @click="editSingle(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Edit Belum</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteSingle(data.item)">
            <feather-icon icon="Trash2Icon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>
    <b-modal id="modal-unverify" hide-footer>
      <template #modal-title> Input Alasan Unverify </template>
      <div class="d-block text-center">
        <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p>
      </div>
      <b-form-group label="Type" label-for="type">
        <v-select
          v-model="selectedType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="typeList"
        />
      </b-form-group>
      <b-form-group>
        <label for="textarea-default">Alasan Unverify : </label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Textarea"
          rows="3"
          v-model="descUnverify"
        />
      </b-form-group>

      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedType == null"
        @click="changeStatusMember(4)"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>

    <b-modal id="modal-detail-pendidikan">
      <template #modal-title> Detail Pendidikan </template>
      <!-- Invoice Description: Total -->

      <table style="width: 100%">
        <tr>
          <td style="width: 30%">Document</td>
          <td style="width: 10%">:</td>
          <td style="width: 30%">
            <a
              :href="detailMember.document"
              target="_blank"
              v-if="detailMember.document"
              >Open</a
            >
            <p v-else>Tidak ada</p>
          </td>
        </tr>
      </table>
      <hr class="invoice-spacing" />
      <div v-for="pendidikan in detailMember.pendidikan" :key="pendidikan.id">
        <table style="width: 100%">
          <tr>
            <td style="width: 30%">Universitas</td>
            <td style="width: 10%">:</td>
            <td style="width: 30%">{{ pendidikan.universitas }}</td>
          </tr>
          <tr>
            <td>Fakultas</td>
            <td>:</td>
            <td>{{ pendidikan.fakultas }}</td>
          </tr>
          <tr>
            <td>Jurusan</td>
            <td>:</td>
            <td>{{ pendidikan.jurusan }}</td>
          </tr>
          <tr>
            <td>Tahun Masuk</td>
            <td>:</td>
            <td>{{ pendidikan.tahun_masuk }}</td>
          </tr>
          <tr>
            <td>Tahun Keluar</td>
            <td>:</td>
            <td>{{ pendidikan.tahun_keluar }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>:</td>
            <td>{{ pendidikan.status }}</td>
          </tr>
          
          <tr>
            <td>Created At</td>
            <td>:</td>
            <td>{{ pendidikan.created_at }}</td>
          </tr>
        </table>
        <hr class="invoice-spacing" />
      </div>
    </b-modal>
    <b-modal id="modal-detail-unverify">
      <template #modal-title> Detail Unverify </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%" v-if="detailMember.status == 4">
        <tr>
          <td>Type</td>
          <td>:</td>
          <td>
            {{ typeList[detailMember.rejection.type - 1].name }}
          </td>
        </tr>
        <tr>
          <td>Description</td>
          <td>:</td>
          <td>{{ detailMember.rejection.description }}</td>
        </tr>
      </table>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Can } from '@casl/vue';
import RoleModal from './Role.vue'
export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    Can,
    RoleModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      // fields: [
      //   { key: 'nama', label: 'Nama' },
      //   'email', 'fakultas', 'jurusan', 'angkatan',
      //   { key: 'pendidikan[0].tahun_keluar', label: 'tahun lulus' },
      //   'document',
      //   'status',
      //   { key: 'created_at', label: 'join date' }, 'action'
      // ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Pre Register', 2: 'Registered', 3: 'Verified', 4: 'Unverified', 5: 'Active',
      },
      {
        1: 'light-info', 2: 'light-info', 3: 'light-primary', 4: 'light-danger', 5: 'light-success',
      }],
      statusEmail: [{
        1: 'Verified', 0: 'Unverified'
      },
      {
        0: 'light-danger', 1: 'light-success',
      }],
      statusList: [
        { id: 1, name: 'Pre Register' },
        { id: 2, name: 'Registered' },
        { id: 3, name: 'Verified' },
        { id: 4, name: 'Unverified' },
        { id: 5, name: 'Active' }

      ],
      typeList: [
        { id: 1, name: "Can't find" },
        { id: 2, name: 'Input wrong data' },
        { id: 3, name: 'Already registered/validated with different email' },
      ],

      selectMode: 'multi',
      selected: [],

      selectedJurusans: [],
      selectedFakultas: [],
      fakultass: [],
      jurusans: [],
      search: null,
      angkatan: null,
      email: null,
      descUnverify: null,
      detailMember: {},
      selectedStatus: [],
      isAdvanceFilter: false,
      created_at: null,
      selectedType: { id: 1, name: "Can't find" },
      singleSelected: {},

    }
  },
  computed: {
    getFields() {
      const fields = [
        { key: 'nama', label: 'Nama' },
        'fakultas',
        'jurusan',
        'angkatan',
        { key: 'pendidikan[0].tahun_keluar', label: 'tahun lulus' },
        'status',
        { key: 'is_email_verified', label: 'email status' },
        { key: 'created_at', label: 'join date' }, 
        'action',
      ]

      if (this.$can('read', 'MemberEmail')) {
        fields.splice(2, 0, 'email')
      }
      // if (this.$can('read', 'MemberFakultas')) {
      //   fields.splice(3, 0, 'fakultas')
      // }
      if (this.$can('read', 'MemberDocument')) {
        fields.splice(6, 0, 'document')
      }

      return fields
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getMember()
      }
      else {
        this.currentPage = 1
      }

    },
    getParamApi() {
      return {
        limit: this.perPage,
        page: this.currentPage,
        search: this.search,
        angkatan: this.angkatan,
        fakultas: this.getFakultasIds(),
        jurusan: this.getJurusansIds(),
        status: this.getStatusIds(),
        created_at: this.created_at,
        total: this.rows

      }

    },
    async getMember() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        let keyFetch = ''
        if (this.$can('read', 'FetchMember')) {
          keyFetch = 'list'
        } else if (this.$can('read', 'FetchMemberByFaculty')) {
          keyFetch = 'listByFaculty'
        } else {
          return
        }
        const { data } = await API.member[keyFetch](this.getParamApi())
        this.data_table = data
        this.items = data.data
        this.rows = data.total

        this.handleMsgSuccess('Berhasil tampilkan data member')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async getFakultas() {

      try {
        const { data } = await API.master.fakultas()
        this.fakultass = data.data

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    async getJurusans() {

      try {
        const { data } = await API.master.jurusan()
        this.jurusans = data.data
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    validateExport() {
      if (this.rows > 500) {
        this.$bvModal.show("modal-export")
      }
      else {
        this.exportData()
      }


    },
    getFakultasIds() {
      var fakultasIds = this.selectedFakultas.length > 0 ? this.selectedFakultas.map(function (item) { return item['id'] }) : null
      return fakultasIds ? fakultasIds.toString() : null
    },
    getJurusansIds() {
      var jurusanIds = this.selectedJurusans.length > 0 ? this.selectedJurusans.map(function (item) { return item['id'] }) : null;
      return jurusanIds ? jurusanIds.toString() : null
    },
    getStatusIds() {
      var statusIds = this.selectedStatus.length > 0 ? this.selectedStatus.map(function (item) { return item['id'] }) : null;
      return statusIds ? statusIds.toString() : null
    },
    getTypeId() {
      return this.selectedType.id
    },
    getMemberIds() {
      return this.selected.length > 0 ? this.selected.map(function (item) { return item['id'] }) : null
    },
    async exportData() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      this.$bvModal.hide("modal-export")
      try {

        const { data } = await API.member.export(this.getParamApi())
        if (data.is_delay) {
          this.sweetAlertSuccess('Export sedang diproses, silahkan cek email secara berkala!')
        }
        else {
          window.open(data.url, '_blank');
        }

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)

      }
    },
    validateVerifyMember() {

      if (this.selected.length == 0) {
        this.toastError('Pilih member terlebih dahulu!')

      } else {
        this.$swal({
          title: 'Warning!',
          text: ' Apakah anda yakin?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.dismiss == 'cancel') {
            console.log('cancel');
          } else {
            this.changeStatusMember(3)
          }

        })

      }

    },
    validateUnverifyMember() {
      if (this.selected.length == 0) {
        this.toastError('Pilih member terlebih dahulu!')
      } else {
        this.$bvModal.show("modal-unverify")
      }


    },
    async changeStatusMember(chosenStatus) {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        var body = {
          status: chosenStatus,
          ids: this.getMemberIds(),
          description: this.descUnverify,
          type: this.getTypeId()
        }
        const { data } = await API.member.status(body)
        this.descUnverify = null
        this.sweetAlertSuccess('Berhasil ubah status member!')
        this.refreshPage()

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$bvModal.hide("modal-unverify")
        this.clearSelected()


      }



    },
    validateDeleteMember() {


      if (this.selected.length == 0) {
        this.toastError('Pilih member terlebih dahulu!')

      } else {
        this.$swal({
          title: 'Warning!',
          text: ' Apakah anda yakin?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.dismiss == 'cancel') {
            console.log('cancel');
          } else {
            this.deleteMember()
          }

        })

      }


    },
    async deleteMember() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

      try {
        var body = {
          ids: this.getMemberIds(),
        }
        const { data } = await API.member.delete(body)
        this.sweetAlertSuccess('Berhasil delete member!')
        this.refreshPage()

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }



    },

    verifySingle(data) {
      //this.clearSelected()
      this.selected = [data]
      this.changeStatusMember(3)
    },
    unverifySingle(data) {
      //this.clearSelected()
      this.selected = [data]
      this.validateUnverifyMember()
    },
    deleteSingle(data) {
      //this.clearSelected()
      this.selected = [data]
      this.validateDeleteMember()
    },
    showDetailMember(item) {
      this.detailMember = item
      this.$bvModal.show("modal-detail-pendidikan")

    },
    showDetailMemberUnverify(item) {
      this.detailMember = item
      this.$bvModal.show("modal-detail-unverify")

    },



  },
  mounted() {
    this.getMember()
    this.getFakultas()
    this.getJurusans()
  },
  watch: {
    currentPage() {
      this.getMember()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
